import { defineStore } from 'pinia'
import { NBanner } from '~/types/api'
import { CmsBannerBox } from '~/types/cms/contentModel'
import { ECustomerTypes } from '~/types/customerType'
import { EQueryParamMap } from '~/types/filters'
import { EConditionSlug } from '~/types/vehicle'
import { EBannerRoutes } from '~/types/cms/contentModelEnum'

interface IBannerStore {
  banners: CmsBannerBox[]
  hidden: number[]
}

export const useBannersStore = defineStore('banners', {
  state: (): IBannerStore => {
    return {
      banners: [],
      hidden: []
    }
  },

  persist: {
    paths: ['hidden']
  },

  getters: {
    getActiveBanners(state): CmsBannerBox[] {
      return state.banners.filter(
        (banner) =>
          !state.hidden.includes(banner.id) &&
          (banner.bodyCopyDesktop || banner.bodyCopyMobile)
      )
    }
  },

  actions: {
    clearBanners() {
      this.banners = []
    },

    async fetch(
      payload: NBanner.getFilterBanner.Payload
    ): Promise<NBanner.getFilterBanner.Response> {
      const response = await this.api.cms.banner.getFilterBanner(payload)

      this.banners = response.error
        ? []
        : response.data.sort((a, b) => a.priority - b.priority)

      return response.data || []
    },

    async fetchPerRoute(routeName: EBannerRoutes): Promise<void> {
      const route = useRoute()
      const routeParams = route.params as {
        condition: EConditionSlug
        type?: ECustomerTypes
      }

      const routeQuery = route.query as {
        [EQueryParamMap.models]?: string | number[]
        [EQueryParamMap.fuels]?: string | number[]
      }

      const queryModels = Array.isArray(routeQuery[EQueryParamMap.models])
        ? (routeQuery[EQueryParamMap.models] as number[])
        : (routeQuery[EQueryParamMap.models] as string)?.split(',').map(Number)
      const queryFuels = Array.isArray(routeQuery[EQueryParamMap.fuels])
        ? (routeQuery[EQueryParamMap.fuels] as number[])
        : (routeQuery[EQueryParamMap.fuels] as string)?.split(',').map(Number)

      await this.fetch({
        VehicleCondition: [routeParams.condition],
        Page: [routeName],
        Mode: [routeParams.type || ECustomerTypes.Private],
        Models: queryModels ?? [],
        Fuel: queryFuels ?? []
      })
    },

    hideBanner(payload: number) {
      this.hidden = [...this.hidden, payload]
    }
  }
})
